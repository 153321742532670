import React, { useState } from "react"
import { Navbar } from "react-bootstrap"
import { Link } from "gatsby"

const CustomNavbar = props => {
  const {
    pageInfo: { pageName },
  } = props
  const [left, setLeft] = useState("-100%")
  const [isHoverPro, setHoverPro] = useState("none")
  const [isHoverCase, setHoverCase] = useState("none")

  const togglepro = () => {
    if (isHoverPro === "none") {
      setHoverPro("block")
      setHoverCase("none")
    } else {
      setHoverPro("none")
    }
  }
  const togglecase = () => {
    if (isHoverCase === "none") {
      setHoverCase("block")
      setHoverPro("none")
    } else {
      setHoverCase("none")
    }
  }

  const activeStyle = {
    color: "#3C51E0",
  }

  const linkStyle = {
    borderBottom: "2px solid blue",
    color: "#3C51E0",
  }

  const proactive = {
    color: "#3C51E0",
  }

  const menu = (
    <div style={{ left: left }} className="pro-menu">
      <div className="pro-menu-list">
        <div className="close-icon">
          <svg className="icon" onClick={() => setLeft("-100%")}>
            <use href="#iconclose2"></use>
          </svg>
        </div>
        <div className="menu-list">
          <div className="menu-list-in">
            <Link to="/" activeStyle={proactive}>
              Home
            </Link>
          </div>
          <div onClick={() => togglepro()} className="menu-list-in">
            <span
              style={{
                cursor: "pointer",
                color:
                  pageName === "saas" || pageName === "onestop"
                    ? "#3C51E0"
                    : "#292A29",
              }}
            >
              Product center
            </span>
            <svg
              className="icon"
              transform={isHoverPro === "block" ? "" : "rotate(270)"}
            >
              <use href="#iconjiantou3"></use>
            </svg>
          </div>
          <div style={{ display: isHoverPro }} className="pro-case">
            <Link to="/saas" activeStyle={proactive}>
              Benefits of BBA
            </Link>
            <Link to="/oneStop" activeStyle={proactive}>
              One-stop Solution
            </Link>
            {/* <Link to='/contact' activeStyle={proactive}>Entry Guide</Link> */}
          </div>
          <div className="menu-list-in">
            <span
              style={{
                cursor: "pointer",
                color: pageName === "case" ? "#3C51E0" : "#292A29",
              }}
            >
              <Link to="/cases" activeStyle={proactive}>
                Website Themes
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div style={{ width: "20%" }} onClick={() => setLeft("-100%")}></div>
    </div>
  )

  return (
    <>
      {menu}
      <div className="nav-box">
        <Navbar collapseOnSelect expand="lg" id="site-navbar" sticky="top">
          <svg className="icon nav-menu" onClick={() => setLeft("0")}>
            <use href="#iconliebiao"></use>
          </svg>
          <Navbar.Brand href="/" className="nav-brand">
            BigBigAds
          </Navbar.Brand>
          <div className="nav-link">
            <div>
              <Link
                to="/"
                activeStyle={linkStyle}
                style={{
                  lineHeight: "88px",
                  display: "block",
                  width: "92px",
                  textAlign: "center",
                }}
              >
                Home
              </Link>
            </div>
            <div
              style={{
                lineHeight: "88px",
                display: "inline-block",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "-40px",
                  right: "-40px",
                  top: "28px",
                  bottom: "0",
                  cursor: "pointer",
                  zIndex: 888,
                }}
                onMouseOver={() => {
                  setHoverPro("block")
                }}
                onMouseOut={() => {
                  setHoverPro("none")
                }}
              >
                <div
                  style={{
                    display: isHoverPro,
                    position: "absolute",
                    lineHeight: "27px",
                  }}
                  className="web-product"
                >
                  <div className="web-info">
                    <Link to="/saas" activeStyle={activeStyle}>
                      Benefits of BBA
                    </Link>
                    <Link to="/oneStop" activeStyle={activeStyle}>
                      ALL-IN-ONE Solution
                    </Link>
                    {/* <Link to='/contact' activeStyle={activeStyle}>Entry Guide</Link> */}
                  </div>
                </div>
              </div>
              <div
                style={{
                  cursor: "pointer",
                  color:
                    pageName === "saas" || pageName === "onestop"
                      ? "#3C51E0"
                      : "#292A29",
                }}
              >
                Product center
                <svg
                  className="icon"
                  transform={isHoverPro === "none" ? "" : "rotate(270)"}
                >
                  <use href="#iconjiantou3"></use>
                </svg>
              </div>
            </div>
            <Link
              to="/cases"
              activeStyle={activeStyle}
              style={{ color: "#292a29", lineHeight: "88px" }}
            >
              Website Themes
            </Link>

            {/* <Link to='/cases' activeStyle={proactive}>Website Themes</Link> */}
            {/* <div style={{cursor:"pointer",lineHeight:'88px',display:'block',color: pageName==='case'?'#3C51E0':'#292A29'}}> */}
            {/* <span style={{cursor:"pointer",color:pageName==='case'?'#3C51E0':'#292A29'}}>
                <Link to='/cases' activeStyle={proactive}>Website Themes</Link>
                </span> */}

            {/* <svg className="icon" transform={isHoverCase==='none'?'':"rotate(270)"}>
                  <use href='#iconjiantou3'></use>
                </svg> */}
            {/* </div> */}
          </div>
          <a className="nav-connect" href="mailto:bbaservice@network.com ">
            Contact us
          </a>
        </Navbar>
      </div>
    </>
  )
}

export default CustomNavbar
